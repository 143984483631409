import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Importo hook-un
import Footer from '../Footer';
import HeroSection from '../HeroSection';
import './Hotels.css';
import ModalHotel from './ModalHotel';

const Hotels = () => {
    const [selectedHotel, setSelectedHotel] = useState(null);
    const { t } = useTranslation(); // Hook për përkthim

    // Array me hotelet
    const hotels = [
        { 
            id: 1, 
            name: t('hotels.1.name'), 
            country: t('hotels.1.country'), 
            description: t('hotels.1.description'),
            details: t('hotels.1.details'),
            images: ["Logu.jpg", "Logu1.jpg", "Logu2.jpg"]
        },
        { 
            id: 2, 
            name: t('hotels.2.name'), 
            country: t('hotels.2.country'), 
            description: t('hotels.2.description'),
            details: t('hotels.2.details'),
            images: ["Grand.jpg", "Grand1.jpg", "Grand2.jpg"]
        },
        { 
            id: 3, 
            name: t('hotels.3.name'), 
            country: t('hotels.3.country'), 
            description: t('hotels.3.description'),
            details: t('hotels.3.details'),
            images: ["Tradita.jpg", "Tradita1.jpg", "Tradita2.jpg"]
        },
        { 
            id: 4, 
            name: t('hotels.4.name'), 
            country: t('hotels.4.country'), 
            description: t('hotels.4.description'),
            details: t('hotels.4.details'),
            images: ["Golden.jpg", "Golden2.jpg", "Golden1.jpg"]
        },
        { 
            id: 5, 
            name: t('hotels.5.name'), 
            country: t('hotels.5.country'), 
            description: t('hotels.5.description'),
            details: t('hotels.5.details'),
            images: ["Breezy.jpg", "Breezy1.jpg", "Breezy2.jpg"]
        },
        { 
            id: 6, 
            name: t('hotels.6.name'), 
            country: t('hotels.6.country'), 
            description: t('hotels.6.description'),
            details: t('hotels.6.details'),
            images: ["Bologna.jpg", "Bologna2.jpg", "Bologna1.jpg"]
        },
        { 
            id: 7, 
            name: t('hotels.7.name'), 
            country: t('hotels.7.country'), 
            description: t('hotels.7.description'),
            details: t('hotels.7.details'),
            images: ["Nobus.jpg", "Nobus1.jpg", "Nobus2.jpg"]
        },
        { 
            id: 8, 
            name: t('hotels.8.name'), 
            country: t('hotels.8.country'), 
            description: t('hotels.8.description'),
            details: t('hotels.8.details'),
            images: ["Royal.jpg", "Royal1.jpg", "Royal2.jpg"]
        },
        { 
            id: 9, 
            name: t('hotels.9.name'), 
            country: t('hotels.9.country'), 
            description: t('hotels.9.description'),
            details: t('hotels.9.details'),
            images: ["Marika.jpg", "Marika2.jpg", "Marika1.jpg"]
        },
        { 
            id: 10, 
            name: t('hotels.10.name'), 
            country: t('hotels.10.country'), 
            description: t('hotels.10.description'),
            details: t('hotels.10.details'),
            images: ["As.jpg", "As1.jpg", "As2.jpg"]
        }
    ];
    
    const openModal = (hotel) => {
        setSelectedHotel(hotel);
    };

    const closeModal = () => {
        setSelectedHotel(null);
    };

    return (
        <div>
            <HeroSection />
            <div className="hotels-container">
                <h1 className="section-title">{t('heroSection.title')}</h1>
                <div className="hotel-grid">
                    {hotels.map((hotel) => (
                        <div key={hotel.id} className="hotel-card">
                            <h5 className="hotel-title">{hotel.name}</h5>
                            <p className="hotel-country">{hotel.country}</p>
                            <p className="hotel-description">{hotel.description}</p>
                            <button className="learn-more-btn" onClick={() => openModal(hotel)}>
                                {t('learnMore')}
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {selectedHotel && (
                <ModalHotel hotel={selectedHotel} closeModal={closeModal} />
            )}

            <Footer />
        </div>
    );
};

export default Hotels;