import React, { useState } from 'react';
import Footer from '../Footer';
import HeroSection from '../HeroSection';
import './Travels.css';
import Modal from './Modal';
import { useTranslation } from 'react-i18next'; // Importohet hook-u i i18next

const Travel = () => {
    const [selectedItinerary, setSelectedItinerary] = useState(null);
    const { t } = useTranslation(); // Hook për përdorimin e përkthimeve

    const itineraries = [
        { 
            id: 1, 
            title: t("itinerary.1.title"), // Përkthimi nga JSON
            description: t("itinerary.1.description"),
            details: t("itinerary.1.details"),
            images: ["/images/4.JPG", "/images/s.jpg"]
        },
        { 
            id: 2, 
            title: t("itinerary.2.title"),
            description: t("itinerary.2.description"),
            details: t("itinerary.2.details"),
            images: ["/images/tirana-main-image-scaled.jpg"]
        },
        { 
            id: 3, 
            title: t("itinerary.3.title"),
            description: t("itinerary.3.description"),
            details: t("itinerary.3.details"),
            images: ["/images/d6.jpg"]
        },
        { 
            id: 4, 
            title: t("itinerary.4.title"),
            description: t("itinerary.4.description"),
            details: t("itinerary.4.details"),
            images: ["/images/146.jpg"]
        },
        { 
            id: 5, 
            title: t("itinerary.5.title"),
            description: t("itinerary.5.description"),
            details: t("itinerary.5.details"),
            images: ["/images/36e5e9_47c9d62726ce41cd81687e8fdf6d5dd5~mv2.jpg"]
        },
    ];

    const openModal = (itinerary) => {
        setSelectedItinerary(itinerary);
    };

    const closeModal = () => {
        setSelectedItinerary(null);
    };

    return (
        <div>
            <HeroSection />
            <div className="itineraries-container">
                <h1 className="section-title">{t("sectionTitle")}</h1> {/* Përdorimi i përkthimit */}
                <div className="itinerary-grid">
                    {itineraries.map((itinerary) => (
                        <div key={itinerary.id} className="itinerary-card">
                            <h5 className="itinerary-title">{itinerary.title}</h5>
                            <p className="itinerary-description">{itinerary.description}</p>
                            <button className="learn-more-btn" onClick={() => openModal(itinerary)}>
                                {t("itinerary.learnMore")}
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {selectedItinerary && (
                <Modal itinerary={selectedItinerary} closeModal={closeModal} />
            )}

            <Footer />
        </div>
    );
};

export default Travel;
