import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Importoni useTranslation
import './Slide.css';

const Slider = () => {
    const { t } = useTranslation();  // Përdorimi i funksionit t() për të marrë përkthimin
    const [activeTab, setActiveTab] = useState('Home');
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            setActiveTab('Home');
            
        } else if (location.pathname === '/Hotels') {
            setActiveTab('Hotels');
        } else if (location.pathname === '/Travels') {
            setActiveTab('Travels');
        } else if (location.pathname === '/licensed-guides') {
            setActiveTab('Licensed Guides');
        } else if (location.pathname === '/esthetic') {
            setActiveTab('Esthetic');
        } else if (location.pathname === '/places-to-visit') {
            setActiveTab('Places to Visit');
        }
    }, [location]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="wrapper">
            <ul className="tabs-box"> 
            
                <li
                    className={`tab ${activeTab === 'Home' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Home')}
                >
                    <NavLink to="/" exact>{t('navbar.home')}</NavLink>  {/* Përdorimi i t() për përkthimin */}
                </li>
          
                <li
                    className={`tab ${activeTab === 'Hotels' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Hotels')}
                >
                    <NavLink to="/Hotels">{t('navbar.hotels')}</NavLink>  {/* Përdorimi i t() për përkthimin */}
                </li>
                <li
                    className={`tab ${activeTab === 'Travels' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Travels')}
                >
                    <NavLink to="/Travels">{t('navbar.travels')}</NavLink>  {/* Përdorimi i t() për përkthimin */}
                </li>
                <li
                    className={`tab ${activeTab === 'Licensed Guides' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Licensed Guides')}
                >
                    <NavLink to="/licensed-guides">{t('navbar.licensed-guides')}</NavLink>  {/* Përdorimi i t() për përkthimin */}
                </li>
                <li
                    className={`tab ${activeTab === 'Esthetic' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Esthetic')}
                >
                    <NavLink to="/esthetic">{t('navbar.esthetic')}</NavLink>  {/* Përdorimi i t() për përkthimin */}
                </li>
                <li
                    className={`tab ${activeTab === 'Places to Visit' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Places to Visit')}
                >
                    <NavLink to="/places-to-visit">{t('navbar.places')}</NavLink>  {/* Përdorimi i t() për përkthimin */}
                </li>
            </ul>
        </div>
    );
};

export default Slider;
