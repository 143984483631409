// About.js
import React from 'react';

import Footer from '../Footer';
import HeroSection from '../HeroSection';
import './About.css';




const AboutUS = () => {
  return (
    <>
      <HeroSection />
  
    
      <div className="about-container">
        <h1 className="about-title">About Us</h1>
        <p className="about-description">
          Welcome to Wanderlust Albania, a premier travel agency based in Shkodra, offering exclusive travel experiences across Albania. 
          We specialize in tailor-made tours, cultural adventures, and authentic local experiences designed for travelers seeking more than just a typical vacation.
        </p>
        <div className="about-services">
          <h2>Our Services</h2>
          <ul className="service-list">
            <li>Guided Tours: Explore Albania with our expert guides</li>
            <li>Adventure Travel: Hiking, kayaking, and more</li>
            <li>Cultural Experiences: Immerse yourself in local traditions and history</li>
            <li>Custom Packages: Tailored tours for your preferences</li>
          </ul>
        </div>
        <div className="about-values">
          <h2>Our Values</h2>
          <p>
            At Wanderlust Albania, we are committed to sustainability, customer satisfaction, and creating unforgettable memories. Our team of passionate travel experts works tirelessly to ensure that each tour is not only fun and exciting but also respectful to the environment and local cultures.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUS;  // Eksportimi default
