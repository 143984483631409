import React, { useState } from 'react';
import './ModalHotel.css';
const ModalHotel = ({ hotel, closeModal }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const handlePrev = () => {
        if (currentImageIndex === 0) {
            setCurrentImageIndex(hotel.images.length - 1); // Shko tek imazhi i fundit
        } else {
            setCurrentImageIndex(currentImageIndex - 1);
        }
    };
    const handleNext = () => {
        if (currentImageIndex === hotel.images.length - 1) {
            setCurrentImageIndex(0); // Kthehu tek imazhi i parë
        } else {
            setCurrentImageIndex(currentImageIndex + 1);
        }
    };
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-btn" onClick={closeModal}>
                    <i className="fas fa-times"></i>
                </button>
                <h2 className="hotel-modal-title">{hotel.title}</h2>
                <p className="hotel-modal-country">{hotel.country}</p>
                <p className="hotel-modal-description">{hotel.description}</p>
                <div className="modal-images">
                    <button className="slider-button prev-btn" onClick={handlePrev}>
                        &#10094;
                    </button>
                    <img
                        src={process.env.PUBLIC_URL + `/images/Hotels/${hotel.images[currentImageIndex]}`}
                        alt={hotel.title}
                        className="hotel-modal-image" />
                    <button className="slider-button next-btn" onClick={handleNext}>
                        &#10095;
                    </button>
                </div>
                <div className="hotel-modal-details">
                    <h3>Details</h3>
                    <p>{hotel.details}</p>
                </div>
                <div className="whatsapp-contact">
                    <a href={`https://wa.me/+355684170770`} target="_blank" rel="noopener noreferrer">
                        <img src="/images/WP.png" alt="WhatsApp" className="whatsapp-icon" />
                        Contact
                    </a>
                </div>
            </div>
        </div>
    );
};
export default ModalHotel;
