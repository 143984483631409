import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import "./TopNavbar.css";

const TopNavbar = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (e) => {
        const selectedLang = e.target.value;
        setLanguage(selectedLang);
        i18n.changeLanguage(selectedLang);  // Ndërron gjuhën dinamikisht
    };

    return (
        <div className="top-navbar">
            <div className="navbar-left">
                {/* Vendosim logon në vend të titullit */}
                <img src="/images/WL.png" alt="Wanderlust Albania Logo" className="navbar-logo" />
            </div>

            <div className="navbar-right">
                {/* Email dhe Location Links */}
                <div className="contact-info">
                    <a href="mailto:info@wanderlustalbania.eu" className="email-link">
                        <i className="fas fa-envelope"></i> info@wanderlustalbania.eu
                    </a>
                    <a href="https://www.google.com/maps/place/Your+Location" className="location-link">
                        <i className="fas fa-map-marker-alt"></i>
                    </a>
                </div>
                <select
                    className="translator"
                    value={language}
                    onChange={handleLanguageChange}
                >
                    <option value="en">{t('navbar.english')}</option>
                    <option value="sq">{t('navbar.albanian')}</option>
                    <option value="de">{t('navbar.german')}</option> {/* Shto opsionin për gjermanishten */}
                    <option value="it">{t('navbar.italian')}</option> {/* Shto opsionin për italishten */}
                    <option value="fr">{t('navbar.french')}</option> {/* Shto opsionin për frëngjishten */}
                </select>
            </div>
        </div>
    );
};

export default TopNavbar;
