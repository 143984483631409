import React from "react";
import { useTranslation } from "react-i18next";
import "./License.css";
import HeroSection from "../HeroSection";
import Footer from "../Footer";

const License = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HeroSection />
      <div className="license-container">
        <h1 className="license-title">{t('title')}</h1>
        <div className="license-section">
          <img src="/images/SADJANI.jpg" alt="Sadjan Maraska" className="license-image" />
          <div className="license-details">
            <h2 className="license-tour-title">{t('guide.name')}</h2>
            <p className="license-description">{t('guide.description')}</p>
       
        
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default License;
