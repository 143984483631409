import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./en.json')
      },
      sq: {
        translation: require('./sq.json')
      },

      de: {
        translation: require('./de.json')
      },
      it: {
        translation: require('./it.json')
      },
      fr: {
        translation: require('./fr.json')
      }
    },
    lng: 'en',  // Gjuha fillestare
    fallbackLng: 'sq',  // Nëse përkthimi nuk ekziston, përdor shqipen
    interpolation: {
      escapeValue: false,
    }
  });

export default i18next;
