import React from 'react';
import './Photo.css';  // Make sure you import your CSS file
import HeroSection from '../HeroSection';  // Import HeroSection component
import Footer from '../Footer';  // Import Footer component

const PhotoPage = () => {
    console.log("PhotoPage rendered");  // For debugging

    return (
        <div>
            {/* Hero Section */}
            <HeroSection />

            {/* Main Content of the Photo Page */}
            <div className="photo-page-container">
                <div className="photo-content">
                    <h1 className="photo-title">Explore Our Destination</h1>

                    <div className="photo-image-container">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/Klinika/Capture.png`}
                            alt="Clinic"
                            className="clinic-image"
                        />
                    </div>

                    <div className="photo-description">
                        <p>Browse through our stunning photo gallery 
                            showcasing some of the best moments captured during our tours and travels.
                             Get inspired for your next adventure.</p>
                    </div>

                    <div className="photo-link-container">
                        <a
                            href="https://wa.me/+355684170770?text=Hello,%20I%20would%20like%20to%20inquire%20about%20your%20services."
                            className="photo-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Contact
                        </a>
                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <Footer />
        </div>
    );
};

export default PhotoPage;
